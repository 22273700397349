import { GetStaticPropsContext, InferGetStaticPropsType } from 'next';
import Link from 'next/link';
import { Section, H } from 'react-headings';
import { useTranslation } from 'react-i18next';
import { getAllMenuPage } from 'repos/menu';

import Layout from 'components/layout';
import SEO from 'components/seo';

export default function Custom404Page({
  menuParams,
}: InferGetStaticPropsType<typeof getStaticProps>) {
  const { t } = useTranslation();

  return (
    <Layout menuParams={menuParams}>
      <SEO title={t('not_found')} />
      <section className="bg-gray-100 pb-32 pt-40">
        <div className="mx-auto flex max-w-7xl flex-col items-center px-6">
          <Section
            component={
              <H className="mb-8 text-center text-6xl font-extrabold">404</H>
            }
          >
            <div className="text-r-lg mb-8 text-center">
              {t('nothing_found_at_this_address')}
            </div>
            <Link href="/">
              <a className="btn-primary">{t('back_to_home')}</a>
            </Link>
          </Section>
        </div>
      </section>
    </Layout>
  );
}

export const getStaticProps = async ({ locale }: GetStaticPropsContext) => {
  const menuParams = await getAllMenuPage({ locale });

  return {
    props: {
      menuParams,
    },
  };
};
